import { useRef, useState, useEffect, useCallback } from "react";
import dropdownIcon from 'assets/img/dropdown-icon.svg'
import dateIcon from 'assets/img/date-icon.svg'
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import toast from "react-hot-toast";
dayjs.extend(quarterOfYear)

const TIME_PERIOD_OPTIONS = [
    'This week',
    'This month',
    'This year',
    'This quarter',
    'Customize'
]
const TimePeriodSelector = ({ onSelectDateRange, isDateValid, periodOptions = TIME_PERIOD_OPTIONS }) => {
    const dropdownRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false);
    const [dateRange, setDateRange] = useState({
        startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().endOf('month').format('YYYY-MM-DD')
    });
    const [value, setValue] = useState('This month');
    const handleMenuOpen = () => {
        setIsOpen(!isOpen)
    }
    const handleSelect = (option) => {
        setIsOpen(false)
        setValue(option);
        if (option != 'Customize') {
            let start, end;
            switch (option) {
                case 'This week':
                    start = dayjs().startOf('week');
                    end = dayjs().endOf('week');
                    break;
                case 'This month':
                    start = dayjs().startOf('month');
                    end = dayjs().endOf('month');
                    break;
                case 'This quarter':
                    start = dayjs().startOf('quarter');
                    end = dayjs().endOf('quarter');
                    break;
                case 'This year':
                    start = dayjs().startOf('year');
                    end = dayjs().endOf('year');
                    break;
                default:
                    return;
            }
            setDateRange({
                startDate: start.format('YYYY-MM-DD'),
                endDate: end.format('YYYY-MM-DD')
            });
        }
    }
    const handleDateCustomizeChange = (e, option) => {
        const value = e.target.value
        if (value) {
            setDateRange((prev) => {
                return {
                    ...prev,
                    [option]: value
                }
            })
        }
    }

    useEffect(() => {
        const start = dayjs(dateRange.startDate)
        const end = dayjs(dateRange.endDate)
        if (!start.isBefore(end)) {
            isDateValid(false)
            toast.error('Start date must be before end date ');
        } else {
            isDateValid(true)
        }
        onSelectDateRange(dateRange);
    }, [dateRange])

    useEffect(() => {
        const handleClickOutside = (e) => {
            if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
                setIsOpen(false)
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])


    return (<div className="flex  flex-col gap-2 ">
        <div
            ref={dropdownRef}
            className="relative flex flex-row gap-2 items-center w-[13rem]  bg-stone-950 rounded-md border 
            border-borderTable px-3 text-sm focus:outline-stone-200 focus:outline cursor-pointer h-[3rem]"
            onClick={e => handleMenuOpen()}
        >
            <img src={dateIcon} alt="wallet icon" className="h-5" />
            <span
                className=" text-stone-200 bg-stone-950 text-sm cursor-pointer w-full"
            >
                {value}
            </span>
            <span className={`absolute right-3 top-[16px] transition-transform cursor-pointer ${isOpen ? 'rotate-180' : ''}`}>
                <img src={dropdownIcon} className='h-3' alt="" />
            </span>
            <div className=' absolute h-7 w-[1px] bg-stone-200 top-2 right-[35px]'></div>
            {isOpen && <ul className="absolute left-0 top-[3.5rem] w-full bg-stone-950 text-stone-200 border border-stone-700
                max-h-[200px] overflow-y-scroll list-none z-10 rounded py-2">
                {periodOptions.map((option, index) =>
                    <li
                        key={index}
                        className="flex flex-row text-sm justify-left items-center px-3 py-2
                         rounded-sm cursor-pointer hover:bg-stone-700 h-[3rem]"
                        onClick={(e) => handleSelect(option)}
                    >
                        <div className='flex flex-col '>
                            <span >{option}</span>
                        </div>
                    </li>
                )}
            </ul>}
        </div>
        {value === 'Customize' && <div className="flex flex-row items-center gap-2 text-stone-200 h-[3rem]">
            <input
                type="date"
                className="max-w-[10rem] h-full text-stone-200 bg-stone-950 rounded-md border border-borderTable px-3 py-2 text-sm 
                            focus:outline-stone-200 focus:outline"
                value={dateRange?.startDate}
                onChange={(e) => handleDateCustomizeChange(e, 'startDate')}
            />
            <span>to</span>
            <input
                type="date"
                className="max-w-[10rem] h-full text-stone-200 bg-stone-950 rounded-md border border-borderTable px-3 py-2 text-sm 
                            focus:outline-stone-200 focus:outline"
                value={dateRange?.endDate}
                onChange={(e) => handleDateCustomizeChange(e, 'endDate')}
            />
        </div>}
    </div>);
}

export default TimePeriodSelector;