import Modal from "components/core/Modal";
import MoneyInputField from "components/core/MoneyInputField";
import { useEffect, useState } from "react";
import { BUDGET_FIELDS } from "configs/budget.config";
import TimePeriodSelector from "components/core/TimePeriodSelector";
import CategorySelector from "components/core/CategorySelector";
import { containsEmptyStringOrNaN, getChangedKeys, isEmptyObj, removeKey, toastError } from "utils/helpers";
import ToggleOnOff from "components/core/ToggleOnOff";
import { useCreateBudgetMutation } from "services/api/BudgetService";
import toast from "react-hot-toast";

const BudgetModal = ({ currentBudget, isOnAdd, isOnEdit, onClose, onCreated }) => {
    const [isOpen, setIsOpen] = useState(isOnAdd || isOnEdit);
    const [formData, setFormData] = useState(currentBudget);
    const [isDataChanged, setIsDataChanged] = useState(false)
    const [isCanSubmit, setIsCanSubmit] = useState(false)
    const [isDateValid, setIsDateValid] = useState(true);
    const [createBudget, { data: createdData, isSuccess: isCreatedSuccess, error: createdError }] = useCreateBudgetMutation();

    const handleChange = (value, field) => {
        if (field !== 'date_range') {
            setFormData((prev) => {
                return {
                    ...prev,
                    [field]: value
                }
            })
        } else {
            setFormData((prev) => {
                return {
                    ...prev,
                    'start_date': value.startDate,
                    'end_date': value.endDate
                }
            })
        }
    }

    const closeModal = () => {
        setIsOpen(false);
        onClose()
    }
    const handleOnSave = async (e) => {
        e.preventDefault()
        if (isCanSubmit && isDataChanged && isOnAdd) {
            await createBudget(formData)
        }
    }
    useEffect(() => {
        if (createdError) {
            toastError(createdError);
        }
        if (isCreatedSuccess) {
            toast.success('budget created!')
            onCreated(createdData.metadata)
            onClose()
            setFormData({})
            setIsOpen(false)
        }
    }, [createdError, isCreatedSuccess, createdData])
    useEffect(() => {
        setIsOpen(isOnAdd || isOnEdit);
    }, [isOnAdd, isOnEdit])

    useEffect(() => {
        const updateKeys = getChangedKeys(formData, currentBudget);
        setIsDataChanged(!isEmptyObj(updateKeys));
        setIsCanSubmit(
            !containsEmptyStringOrNaN(removeKey(formData, BUDGET_FIELDS.isRepeat))
            && formData[BUDGET_FIELDS.value] > 0
            && isDateValid)
    }, [formData, currentBudget, isDateValid]);

    return (
        <Modal
            isOpen={isOpen}
            onClose={closeModal}
            isForm
            handleOnSave={handleOnSave}
            isDataChanged={isDataChanged}
            isCanSubmit={isCanSubmit}
        >
            <h4 className="mb-5 text-lg font-bold">Your Budget</h4>
            <div className="flex flex-col gap-4 w-[25rem]">
                <label className="font-bold text-sm text-stone-300">Select Category</label>
                <div className="flex flex-col gap-2 text-base w-[15rem]">
                    <CategorySelector
                        type='expense'
                        initialCatId={formData && formData[BUDGET_FIELDS.categoryId]}
                        onSelectCat={(value) => handleChange(value, BUDGET_FIELDS.categoryId)}
                    />
                </div>
                <div className="flex flex-col gap-2 text-base w-[15rem]">
                    <MoneyInputField
                        label='Amount'
                        initialValue={formData && formData[BUDGET_FIELDS.value]}
                        onChangeAmount={value => handleChange(value, BUDGET_FIELDS.value)}
                    />
                </div>
                <div className="flex flex-col gap-2 text-base w-[25rem]">
                    <label className="font-bold text-sm text-stone-300">Date Range</label>
                    <TimePeriodSelector
                        onSelectDateRange={value => handleChange(value, 'date_range')}
                        isDateValid={(value) => setIsDateValid(value)}
                    />
                </div>
                <div className="flex flex-col gap-2 text-base w-[25rem]">
                    <label className="font-bold text-sm text-stone-300">Repeat next time?</label>
                    <ToggleOnOff
                        isOn={formData && formData[BUDGET_FIELDS.isRepeat]}
                        onToggle={value => handleChange(value, BUDGET_FIELDS.isRepeat)} />
                </div>
            </div>
        </Modal>
    );
}

export default BudgetModal;