import comingSoon from 'assets/img/coming-soon.svg'
import addIcon from 'assets/img/create-icon.svg'
import BudgetModal from 'components/budget-page/ModalBudget';
import TimePeriodSelector from 'components/core/TimePeriodSelector';
import { BLANK_BUDGET } from 'configs/budget.config';
import { useEffect, useState } from 'react';
import { useGetBudgetPeriodExistQuery } from 'services/api/BudgetService';
import { getLabelForDateRange, sortLabels } from 'utils/helpers';
const Budgets = () => {
    const [currentBudget, setCurrentBudget] = useState(BLANK_BUDGET);
    const [isAddClick, setIsAddClick] = useState(false);
    const [periodOptions, setPeriodOptions] = useState(['This Month']);
    const { data: periodsExisting, error: getPeriodError, isLoading: isGettingPeriodExist } = useGetBudgetPeriodExistQuery();
    const handleClickAdd = () => {
        setIsAddClick(true);
        setCurrentBudget(BLANK_BUDGET)
    }

    const handleOnCloseModal = () => {
        setIsAddClick(false);
    }

    useEffect(() => {
        const periodOptionsData = periodsExisting?.metadata
        if (periodOptionsData) {
            setPeriodOptions(() => {
                const labels = periodOptionsData.map(range => {
                    return getLabelForDateRange(range.startDate, range.endDate);
                })
                return sortLabels(labels)
            })
        }
    }, [periodsExisting])

    const handleCreatedBudget = (newBudget) => {
        console.log(newBudget)
    }

    const handlePeriodChange = (value) => {
        // console.log(value)
    }


    return (
        <div className="flex flex-col mb-[10rem] w-full p-10">
            <div className="py-5 flex flex-row justify-between text-stone-200">
                <h3 className="font-bold xl:text-lg phone:text-base">Your budgets</h3>
            </div>
            <TimePeriodSelector
                onSelectDateRange={handlePeriodChange}
                isDateValid={() => { }}
                periodOptions={periodOptions}
            />
            <div
                className='w-100 flex justify-center items-center h-[6rem] border border-dashed border-stone-200 cursor-pointer rounded-md'
                onClick={handleClickAdd}
            >
                <img src={addIcon} className='h-[4rem]' alt="" />
            </div>
            <div className='w-100 flex justify-center'>
                <img src={comingSoon} className='' alt="" />
            </div>
            <BudgetModal
                currentBudget={currentBudget}
                isOnAdd={isAddClick}
                onClose={handleOnCloseModal}
                onCreated={handleCreatedBudget}
            />
        </div>
    );
}

export default Budgets;