import { createApi } from "@reduxjs/toolkit/query/react"
import { baseQueryWithReAuth } from "./QueryReAuth"
export const budgetApi = createApi({
    reducerPath: 'budgetApi',
    baseQuery: baseQueryWithReAuth,
    endpoints: (builder) => ({
        createBudget: builder.mutation({
            query: ({ ...body }) => {
                return {
                    url: `/budget`,
                    method: 'POST',
                    body: body
                }
            }
        }),
        getBudgetsByTimeRange: builder.query({
            query: ({ startDate, endDate }) => {
                return {
                    url: `/budget?startDate=${startDate}&endDate=${endDate}`,
                }
            }
        }),
        getBudgetPeriodExist: builder.query({
            query: () => {
                return {
                    url: '/budget/time-period',
                }
            }
        })
    })
})

export const {
    useCreateBudgetMutation,
    useGetBudgetPeriodExistQuery,
    useLazyGetBudgetsByTimeRangeQuery
} = budgetApi
